var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("文件上传/下载")]), _c('file-upload', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "multi": "",
      "limit": 5
    },
    model: {
      value: _vm.fileInfo,
      callback: function ($$v) {
        _vm.fileInfo = $$v;
      },
      expression: "fileInfo"
    }
  }), _c('br'), _vm._v(" " + _vm._s(_vm.fileInfo) + " "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。"), _c('br'), _vm._v("绑定值为单个对象或数组（取决于是否选开启多个文件上传）："), _c('code', [_vm._v("{url: 完整下载链接, name: 文件名, size: 文件大小(单位字节，非必须)}")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };