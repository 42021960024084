export const events = [{
  title: "事件名",
  key: "name",
  width: 150
}, {
  title: "说明",
  key: "type",
  width: 300
}, {
  title: "返回值",
  key: "value"
}];
export const props = [{
  title: "属性",
  key: "name",
  width: 135
}, {
  title: "说明",
  key: "desc"
}, {
  title: "类型",
  key: "type",
  width: 130
}, {
  title: "默认值",
  key: "value"
}];
export const methods = [{
  title: "方法名",
  key: "name",
  width: 150
}, {
  title: "说明",
  key: "type",
  width: 300
}, {
  title: "参数",
  key: "value"
}];